import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CButton, CCol, CContainer, CJumbotron, CRow, CSpinner } from '@coreui/react';
import { startOfToday } from 'date-fns';

import onlyAdmin from '../onlyAdmin';
import EventCard from '../../components/EventCard';
import { getPastEventsQuery, getUpcomingEventsQuery } from '../../graphql/product';

import './style.scss'

const today = startOfToday();

const Events = () => {
  const history = useHistory();

  const {
    loading: loadingUpcomingEvents,
    data: { products: upcomingEvents } = {},
    error: errorLoadingUpcomingEvents
  } = useQuery(getUpcomingEventsQuery, { variables: { today }, fetchPolicy: "network-only" });

  const {
    loading: loadingPastEvents,
    data: { products: pastEvents } = {},
    error: errorLoadingPastEvents
  } = useQuery(getPastEventsQuery, { variables: { today }, fetchPolicy: "network-only" });

  const renderEvents = (events, loading, error) => {
    if (loading) {
      return (
        <CJumbotron color="light">
          Loading events...
          <CSpinner className="ml-2" size="lg" color="primary" variant="grow" />
        </CJumbotron>
      )
    }

    if (events && events.length > 0 && !error) {
      return (
        <CRow>
          {events.map((event) => (
            <CCol lg={3} md={4} sm={6} xs={12}>
              <EventCard event={event} />
            </CCol>
          ))}
        </CRow>
      )
    }

    return (
      <CJumbotron color="light">No events</CJumbotron>
    )
  }

  return (
    <CContainer className="events" fluid>
      <h3>Events</h3>
      <h5>Upcoming Events</h5>
      {renderEvents(upcomingEvents, loadingUpcomingEvents, errorLoadingUpcomingEvents)}
      {!loadingUpcomingEvents && <CButton as={Link} className="add-event-button" to="/events/add" variant="outline" color="primary">Add event</CButton>}
      <h5 className="bottom-header">Past Events</h5>
      {renderEvents(pastEvents, loadingPastEvents, errorLoadingPastEvents)}
    </CContainer>
  )
}

export default onlyAdmin(Events);