import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import { CAlert, CButton, CCard, CCardBody, CCol, CContainer, CImg, CJumbotron, CModal, CModalBody, CModalHeader, CRow, CSpinner } from '@coreui/react';

import EventOrders from '../../components/EventOrders';
import AdditionalQuestions from '../../components/AdditionalQuestions';
import onlyAdmin from '../onlyAdmin';
import { addQuestionMutation, deleteQuestionMutation, getEventByIdQuery, getEventQuestionsQuery, updateEventMutation, updateQuestionMutation } from '../../graphql/product';

import './style.scss';
import EventForm from '../../components/EventForm';
import ImageUploadForm from '../../components/ImageUploadForm';
import { format } from 'date-fns-tz';
import { changeEventImageMutation } from '../../graphql/upload';
import { useToasts } from 'react-toast-notifications';
import { isPast, startOfToday } from 'date-fns';

const today = startOfToday();

const EventDetail = () => {
  const { id } = useParams();

  const [showEditEvent, setShowEditEvent] = useState(false);
  const [showEditImage, setShowEditImage] = useState(false);

  const { addToast } = useToasts();

  const [getEvent, {
    loading: loadingEvent,
    data: { product: event } = {},
    error: errorLoadingEvent,
    called: eventLoaded
  }] = useLazyQuery(getEventByIdQuery);

  const [updateEvent, {
    loading: updatingEvent,
    error: errorUpdatingEvent
  }] = useMutation(updateEventMutation, { refetchQueries: [{ query: getEventByIdQuery, variables: { id } }] })

  const [changeEventImage, {
    loading: changingEventImage,
    error: errorChangingEventImage
  }] = useMutation(changeEventImageMutation, { refetchQueries: [{ query: getEventByIdQuery, variables: { id } }] })

  const [addQuestion, {
    loading: addingQuestion,
    error: errorAddingQuestion
  }] = useMutation(addQuestionMutation, { refetchQueries: [{ query: getEventQuestionsQuery, variables: { id } }] })

  const [updateQuestion, {
    loading: updatingQuestion,
    error: errorUpdatingQuestion
  }] = useMutation(updateQuestionMutation, { refetchQueries: [{ query: getEventQuestionsQuery, variables: { id } }] })

  const [deleteQuestion, {
    loading: deletingQuestion,
    error: errorDeletingQuestion
  }] = useMutation(deleteQuestionMutation, { refetchQueries: [{ query: getEventQuestionsQuery, variables: { id } }] })


  useEffect(() => {
    if (id) {
      getEvent({ variables: { id } })
    }
  }, [id, getEvent])

  if (loadingEvent || !eventLoaded) {
    return (
      <CJumbotron color="light">
        Loading event...
        <CSpinner className="ml-2" size="lg" color="primary" variant="grow" />
      </CJumbotron>
    )
  }

  if (errorLoadingEvent || _.isEmpty(event)) {
    return <Redirect to="/404" />
  }

  const doneEdit = () => setShowEditEvent(false);
  const startEdit = () => setShowEditEvent(true);

  const { name, description, time, image, currency, price, openat, closeat, additional_questions } = event;

  const imageView = (image && image.url) ? (
    <CImg
      src={`${process.env.REACT_APP_SERVER_URL}${image.url}`}
      fluid
      width={200}
      height={200}
      block
      className="event-image"
    />
  ) : (
    <CCard className="upload-button">
      <CCardBody>Upload Image</CCardBody>
    </CCard>
  )

  const isPastEvent = isPast(new Date(time));

  const onEditEvent = async (values) => {
    const updateEventVariables = {
      id,
      name: values.name,
      description: values.description,
      time: new Date(values.time),
      currency: values.currency,
      price: values.price,
      openat: format(new Date(values.openat), 'yyyy-MM-dd', { timeZone: 'Asia/Jakarta' }),
      closeat: format(new Date(values.time), 'yyyy-MM-dd', { timeZone: 'Asia/Jakarta' })
    };

    await updateEvent({ variables: updateEventVariables });

    addToast(`Successfully edit event detail for ${values.name}`, { appearance: 'success' });
    setShowEditEvent(false);
  }

  const onChangeImage = async (values) => {
    await changeEventImage({ variables: { eventId: id, file: values.image } })

    addToast(`Successfully change image for event ${values.name}`, { appearance: 'success' });
    setShowEditImage(false);
  }

  const onCopyRegistrationLink = () => {
    window.navigator.clipboard.writeText(`https://order.indonesiantutors.com/order?productid=${id}`);
    addToast(`Registration link for ${name} copied!`, { appearance: 'info' });

  }

  const onQuestionChange = async (values) => {
    const questionVariables = {
      label: values.label,
      shortlabel: values.shortlabel,
      required: values.required,
      type: values.type,
      product: id
    };

    if (['radio', 'checkboxes'].includes(values.type)) {
      questionVariables.options = values.options
    }

    const isExistingQuestion = !(values.id && values.id.toString().startsWith('new-'));

    if (isExistingQuestion) {
      await updateQuestion({ variables: { ...questionVariables, id: values.id } })
      addToast(`Successfully updated question for event ${name}`, { appearance: 'success' });
    } else {
      await addQuestion({ variables: questionVariables })
      addToast(`Successfully added new question for event ${name}`, { appearance: 'success' });
    }
  }

  const onQuestionRemoved = (id) => async () => {
    await deleteQuestion({ variables: { id } })
    addToast(`Successfully removed question for event ${name}`, { appearance: 'success' });
  }

  return (
    <CContainer className="event-detail" fluid>
      <CRow>
        <CCol md={6} xs={12}>
          <h3 className="page-head">{name || ''} </h3>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg={5} md={6} xs={12}>
          {imageView}
          <span className="read">
            {format(new Date(time), "EEEE, MMMM do yyyy, HH:mm", { timeZone: 'Asia/Jakarta' })} Jakarta Time
          </span>
          <h5><b>{currency} {price}</b></h5>
          <p className="read">
            <i>{description ? `"${description}"` : 'This event has no description'}</i>
          </p>
          {isPastEvent ? (
            <CAlert color="primary">This is past event. You cannot edit the event details anymore.</CAlert>
          ) : (
            <div className="event-buttons">
              <CButton color="primary" variant="outline" onClick={startEdit}>Edit Event Details</CButton>
              <CButton color="primary" variant="outline" onClick={() => setShowEditImage(true)}>Change Image</CButton>
              <CButton color="primary" variant="outline" onClick={onCopyRegistrationLink}>Copy Registration Link</CButton>
            </div>
          )}
          <CModal closeOnBackdrop={false} size="lg" show={showEditEvent} onClose={doneEdit}>
            <CModalHeader closeButton>Edit {name || 'event'}</CModalHeader>
            <CModalBody>
              {!!errorUpdatingEvent && <CAlert closeButton color="danger">An error occured. Please try again.</CAlert>}
              <EventForm event={event} onSubmit={onEditEvent} submitting={updatingEvent} submitButtonText="Save" submittingButtonText="Saving your changes..." />
            </CModalBody>
          </CModal>
          <CModal closeOnBackdrop={false} size="md" show={showEditImage} onClose={() => setShowEditImage(false)}>
            <CModalHeader closeButton>Change Event Image</CModalHeader>
            <CModalBody>
              {!!errorChangingEventImage && <CAlert closeButton color="danger">An error occured. Please try again.</CAlert>}
              <ImageUploadForm onSubmit={onChangeImage} submitting={changingEventImage} submitButtonText="Save" submittingButtonText="Saving your changes..." />
            </CModalBody>
          </CModal>
        </CCol>
        <CCol lg={2} md={1} xs={0} />
        <CCol md={5} xs={12}>
          <h6>Questions for participants</h6>
          <AdditionalQuestions
            allowEdit={!isPastEvent}
            additionalQuestions={additional_questions}
            submitting={updatingQuestion || addingQuestion || deletingQuestion}
            error={errorUpdatingQuestion || errorAddingQuestion || errorDeletingQuestion}
            onUpdateQuestion={onQuestionChange}
            onDeleteQuestion={onQuestionRemoved}
          />
          {isPastEvent && (
            <CAlert color="primary">This is past events. You cannot edit or add questions anymore.</CAlert>
          )}
        </CCol>
      </CRow >
      <hr />
      <h5>Participants</h5>
      <EventOrders eventId={id} additionalQuestions={additional_questions} />
    </CContainer >
  )
}

export default onlyAdmin(EventDetail);