import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CFooter, CHeader, CHeaderNav, CImg, CSidebar, CSidebarBrand, CSidebarNav, CSidebarNavItem } from '@coreui/react';
import React from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';

import { useAuth } from "../../hooks/auth";
import logo from '../../images/logo-intors.png';

import './style.scss'

const onlyAdmin = (Component) => {
  const WrappedComponent = (props) => {
    const { pathname } = useLocation();
    const {
      auth,
      isAuthed,
      removeAuth
    } = useAuth();

    if (!isAuthed) {
      return <Redirect to="/login" exact />
    }

    return (
      <div className="c-app">
        <CSidebar>
          <CSidebarBrand>
            <CImg
              className="intors-logo"
              src={logo}
            />
            <h6>Indonesian Tutors</h6>
          </CSidebarBrand>
          <CSidebarNav>
            <CSidebarNavItem>
              <Link to="/" className={`c-sidebar-nav-link ${pathname === '/' ? 'c-active' : ''}`}>
                Home
              </Link>
            </CSidebarNavItem>
            <CSidebarNavItem>
              <Link to="/events" className={`c-sidebar-nav-link ${pathname.startsWith('/events') ? 'c-active' : ''}`}>
                Events
              </Link>
            </CSidebarNavItem>
          </CSidebarNav>
        </CSidebar>
        <div class="c-wrapper">
          <CHeader>
            <CHeaderNav className="px-3">
              <CDropdown inNav>
                <CDropdownToggle caret color="primary">Hi, {auth.adminName}</CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem onClick={removeAuth}>Log out</CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </CHeaderNav>
          </CHeader>
          <div class="c-body">
            <Component {...props} />
          </div>
          <CFooter />
        </div>
      </div>
    )
  }

  return WrappedComponent
}

export default onlyAdmin