import React from 'react';
import { useQuery } from '@apollo/client';
import { CBadge, CDataTable, CJumbotron, CSpinner } from '@coreui/react';
import _ from 'lodash';

import { getOrdersByEventIdQuery } from '../../graphql/order';

const EventOrders = ({ eventId, additionalQuestions }) => {
  const {
    loading: loadingEventOrders,
    data: { orders } = {},
    error: errorLoadingEventOrders
  } = useQuery(getOrdersByEventIdQuery, { variables: { eventId }, fetchPolicy: "network-only" });

  if (loadingEventOrders) {
    return (
      <CJumbotron color="light">
        Loading participants...
        <CSpinner className="ml-2" size="lg" color="primary" variant="grow" />
      </CJumbotron>
    )
  }

  const getSingleAnswerFromOption = (answer, options) => {
    if (!_.isEmpty(options)) {
      const answerOption = options.find((o) => o.id === answer);
      return (answerOption && answerOption.name) || '';
    }

    return '';
  }

  const getMultipleAnswersFromOption = (answers, options) => {
    if (!_.isEmpty(options) && !_.isEmpty(answers)) {
      return options
        .filter((o) => answers.includes(o.id))
        .map((o) => o.name)
        .join()
    }

    return '';
  }

  const fields = [
    { key: 'no' },
    { key: 'participants' },
    { key: 'status' }
  ]

  const scopedSlots = {
    participants: ({ name, email }) => (
      <td>
        <h6>
          {name}<br />
          <small>{email}</small>
        </h6>
      </td>
    ),
    status: ({ payment }) => (
      <td>
        {(payment && payment.id) ? <CBadge color="success">PAID</CBadge> : <CBadge color="secondary">WAITING FOR PAYMENT</CBadge>}
      </td>
    )
  }

  if (!_.isEmpty(additionalQuestions)) {
    additionalQuestions.forEach(({ key, shortlabel, type, options }) => {
      fields.push({ key, label: shortlabel });

      if (type === 'radio') {
        scopedSlots[key] = (item) => <td>{getSingleAnswerFromOption(item[key], options)}</td>
      } else if (type === 'checkboxes') {
        scopedSlots[key] = (item) => <td>{getMultipleAnswersFromOption(item[key], options)}</td>
      }
    })
  }

  const data = (!_.isEmpty(orders) && !errorLoadingEventOrders) ? (
    orders.map(({ name, email, otherdetails }, index) => ({
      no: index + 1,
      name,
      email,
      ...otherdetails
    }))
  ) : []

  return (
    <CDataTable items={data} fields={fields} scopedSlots={scopedSlots} size="sm" striped />
  )
}

export default EventOrders;