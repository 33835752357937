import React from 'react';
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from "@coreui/react"

const Dropdown = ({ value, name, options, setValue, setTouched }) => {
  const onValueChange = (id) => () => {
    setValue(id);
    setTouched();
  }

  const onBlur = () => {
    setTouched();
  }

  const selectedOption = options.find((v) => v.id === value);

  return (
    <CDropdown
      onBlur={onBlur}
    >
      <CDropdownToggle caret color={!!value ? 'info' : 'light'}>
        {(selectedOption && selectedOption.name) || '..select value from the option'}
      </CDropdownToggle>
      <CDropdownMenu>
        {options && options.map(({ id, name }) => <CDropdownItem onClick={onValueChange(id)}>{name}</CDropdownItem>)}
      </CDropdownMenu>
    </CDropdown>
  )
}

export default Dropdown;
