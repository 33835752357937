import { useState } from "react"

export const useBooleanState = (initialValue) => {
  const [state, setState] = useState(!!initialValue);

  const setStateOn = () => setState(true);
  const setStateOff = () => setState(false);
  const setStateToggle = () => setState(val => !val);

  return [state, setStateOn, setStateOff, setStateToggle]
}

export const useArrayState = (initialValue) => {
  const [state, setState] = useState(initialValue || []);

  const addItemToState = (newItem) => setState(value => [...value, newItem]);
  const removeItemFromState = (itemToRemove) => setState(value => value.filter((v) => v !== itemToRemove));

  return [state, addItemToState, removeItemFromState]
}