import { CButton, CModal, CModalBody } from '@coreui/react';
import React, { useEffect } from 'react';
import { useBooleanState } from '../../hooks/types';

const DeleteButton = ({ onClick, confirmationText, ...props }) => {
  const [confirmDelete, showConfirmDelete, hideConfirmDelete] = useBooleanState(false);

  useEffect(() => {
    return () => hideConfirmDelete()
  }, [])

  return (
    <>
      <CButton {...props} onClick={showConfirmDelete} />
      <CModal size="sm" show={confirmDelete}>
        <CModalBody className="d-flex flex-column align-items-stretch">
          <p>{confirmationText || 'Are you sure you want to delete the item? This cannot be undone!'}</p>
          <CButton size="sm" className="mb-2" color="danger" variant="ghost" onClick={onClick}>Yes, delete</CButton>
          <CButton size="sm" color="danger" onClick={hideConfirmDelete}>No, cancel</CButton>
        </CModalBody>
      </CModal>
    </>
  )
}

export default DeleteButton