import React, { Children } from 'react';
import { Form } from 'formik';
import { CButton } from '@coreui/react';

import './style.scss'

const FormWrapper = ({ className, children, submitButtonText, submittingButtonText, submitting, onCancel, cancelButtonText, dirty, allowSubmitOnClean }) => {
  const disabledOnClean = allowSubmitOnClean && !dirty;

  return (
    <Form className={`form-wrapper ${className}`}>
      {Children.toArray(children)}
      <div className="action-wrapper">
        {onCancel ? <CButton disabled={submitting || disabledOnClean} onClick={onCancel} variant="ghost" color="primary">{cancelButtonText || 'Cancel'}</CButton> : <div />}
        <CButton disabled={submitting || disabledOnClean} type="submit" color="primary">
          {submitting ? (submittingButtonText || 'Submitting...') : (submitButtonText || 'Submit')}
        </CButton>
      </div>
    </Form>
  )
}

export default FormWrapper