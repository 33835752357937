import React, { useState, useCallback, useEffect } from 'react';
import { CCard, CCardBody, CCardImg, CButton, CCardImgOverlay } from '@coreui/react';
import { useDropzone } from 'react-dropzone';
import CIcon from '@coreui/icons-react';
import { cilTrash } from '@coreui/icons';
import './style.scss'

const ImageUpload = ({ originalImageUrl, value, placeholder, setValue, setTouched }) => {
  const [showDropzone, setShowDropzone] = useState(true);
  const [fileBase64, setFileBase64] = useState('');

  useEffect(() => {
    if (value) {
      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        setFileBase64(reader.result);
        setShowDropzone(false);
      }
    } else {
      setFileBase64('');
      setShowDropzone(!originalImageUrl);
    }
  }, [value, originalImageUrl])

  const onRemoveImage = () => {
    setFileBase64('');
    setShowDropzone(true);
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setValue(acceptedFiles[0]);
      setTouched();
    }
  }, [setValue, setTouched])

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const imageSrc = fileBase64 || originalImageUrl;

  return (
    <CCard className="image-upload">
      {!showDropzone && (
        <>
          <CCardImg className="image" src={imageSrc} />
          <CCardImgOverlay>
            <CButton
              className="remove-button"
              onClick={onRemoveImage}
              color="danger"
              size="sm"
              type="button"
            >
              <CIcon size="sm" content={cilTrash} />
            </CButton>
          </CCardImgOverlay>
        </>

      )}
      {showDropzone && (
        <CCardBody className="bg-light dropzone-body">
          <div className="dropzone-wrapper text-dark" {...getRootProps()}>
            <input {...getInputProps()} />
            <p>{placeholder || 'Click or drag image here'}</p>
          </div>
        </CCardBody>
      )}
    </CCard>
  )
}

export default ImageUpload;