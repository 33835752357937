import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CAlert, CButton, CCard, CCardBody, CCol, CContainer, CNav, CNavItem, CNavLink, CRow } from '@coreui/react';
import _ from 'lodash';

import onlyAdmin from '../onlyAdmin';
import { addEventMutation, addQuestionMutation } from '../../graphql/product';
import { uploadMutation } from '../../graphql/upload';

import EventForm from '../../components/EventForm';
import ImageUploadForm from '../../components/ImageUploadForm';
import AdditionalQuestions from '../../components/AdditionalQuestions';

import './style.scss';
import { format } from 'date-fns-tz';
import { useToasts } from 'react-toast-notifications';

const pageTitle = [
  'Add your event details',
  'Add event image (optional)',
  'Add questions for participants (optional)',
  'Review & Submit Your Event'
]

const AddEvent = () => {
  const history = useHistory();

  const [step, setStep] = useState(0);
  const [event, setEvent] = useState({});
  const [questions, setQuestions] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  const [uploadImage] = useMutation(uploadMutation);
  const [addEvent] = useMutation(addEventMutation);
  const [addQuestion] = useMutation(addQuestionMutation);

  const { addToast } = useToasts();

  const setEventDetails = (values) => {
    setEvent({ ...event, ...values });
    setStep(1);
  }

  const setEventImage = ({ image }) => {
    setEvent({ ...event, image });
    setStep(2);
  }

  const setQuestionItem = useCallback((question) => {
    const questionAlreadyExists = (question && question.id && questions.some((q) => q.id === question.id))
    const newQuestions = questionAlreadyExists
      ? questions.map((q) => q.id === question.id ? { ...q, ...question } : q)
      : [...questions, question]

    setQuestions(newQuestions)
  }, [questions])

  const deleteQuestionItem = useCallback((id) => () => {
    setQuestions(questions.filter((q) => q.id !== id))
  }, [questions])

  const renderSummaryItem = (label, content) => {
    return (
      <CRow className="summary-item">
        <CCol md={3} sm={5} xs={12}>{label}</CCol>
        <CCol md={9} sm={7} xs={12}>{content}</CCol>
      </CRow>
    )
  }

  const { name, description, time, openat, currency, price, image } = event;

  const onAddEvent = async () => {
    try {
      setSubmitting(true);
      setError('');
  
      let imageId = '';
      if (image) {
        const uploadImageResult = await uploadImage({ variables: { file: image } })
        imageId = _.get(uploadImageResult, 'data.upload.id', '');
      }
  
      const addEventVariables = {
        name,
        description,
        time,
        currency,
        price,
        openat: format(openat, 'yyyy-MM-dd', { timeZone: 'Asia/Jakarta' }),
        closeat: format(time, 'yyyy-MM-dd', { timeZone: 'Asia/Jakarta' })
      };

      if (imageId) {
        addEventVariables.image = imageId;
      }
  
      const addEventResult = await addEvent({ variables: addEventVariables });
      const eventId = _.get(addEventResult, 'data.createProduct.product.id', '');
  
      if (!eventId) {
        throw new Error();
      }
  
      if (!_.isEmpty(questions)) {
        await Promise.all(questions.map((question) => addQuestion({ variables: { ...question, product: eventId } })))
      }

      setSubmitting(true);
      addToast('Event successfully added!', { appearance: 'success' });
      history.push(`/events/${eventId}`)
    } catch(e) {
      console.log(e)
      setSubmitting(false);
      setError('An error occured. Please try again.')
    }
  }

  console.log(questions)

  return (
    <CContainer className="add-event" fluid>
      <CRow>
        <CCol md={3} xs={0}>
          <CNav disabled={submitting} className="event-nav" vertical variant="pills">
            <CNavItem>
              <CNavLink active={step === 0} onClick={() => setStep(0)}>Step 1: Details</CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink active={step === 1} disabled={_.isEmpty(event)} onClick={() => setStep(1)}>Step 2: Image</CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink active={step === 2} disabled={_.isEmpty(event)} onClick={() => setStep(2)}>Step 3: Questions</CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink active={step === 3} disabled={_.isEmpty(event)} onClick={() => setStep(3)}>Review &amp; Submit</CNavLink>
            </CNavItem>
          </CNav>
        </CCol>
        <CCol md={9} xs={0}>
          <div className="form-wrapper">
            <h3 className="page-head">{pageTitle[step]} </h3>
            {(step === 0) && <EventForm event={event} onSubmit={setEventDetails} submitButtonText="Next >" />}
            {(step === 1) && <ImageUploadForm image={event.image} onSubmit={setEventImage} onCancel={() => setStep(0)} cancelButtonText="< Back" submitButtonText="Next >" />}
            {(step === 2) && (
              <>
                <AdditionalQuestions additionalQuestions={questions} allowEdit onUpdateQuestion={setQuestionItem} onDeleteQuestion={deleteQuestionItem} />
                <div className="action-wrapper">
                  <CButton onClick={() => setStep(1)} variant="ghost" color="primary">&lt; Back</CButton>
                  <CButton onClick={() => setStep(3)} color="primary">Next &gt;</CButton>
                </div>
              </>
            )}
            {(step === 3) && (
              <>
                <CCard>
                  <CCardBody>
                    {renderSummaryItem('Event Name', <b>{name || '-'}</b>)}
                    {renderSummaryItem('Event Description', <b>{description || '-'}</b>)}
                    {renderSummaryItem('Event Date & Time', <b>{format(time, 'EEEE, MMMM do yyyy, HH:mm', { timeZone: 'Asia/Jakarta' })}</b>)}
                    {renderSummaryItem('Event Price', <b>{currency} {price}</b>)}
                    {/* {renderSummaryItem('Registration Opens At', <b>{format(openat, 'EEEE, MMMM do yyyy', { timeZone: 'Asia/Jakarta' })}</b>)} */}
                    {renderSummaryItem('Questions for Participants', <AdditionalQuestions additionalQuestions={questions} />)}
                  </CCardBody>
                </CCard>
                {!!error && <CAlert closeButton color="danger">{error}</CAlert>}
                <div className="action-wrapper">
                  <CButton disabled={submitting} onClick={() => setStep(2)} variant="ghost" color="primary">&lt; Back</CButton>
                  <CButton disabled={submitting} onClick={onAddEvent} color="primary">{submitting ? 'Submitting...' : 'Submit'}</CButton>
                </div>
              </>
            )}
          </div>
        </CCol>
      </CRow>
    </CContainer>
  )
}

export default onlyAdmin(AddEvent);