import gql from 'graphql-tag';

export const loginMutation = gql`
  mutation ($email: String!, $password: String!) {
    login(input: { identifier: $email, password: $password }) {
      jwt
      user {
        id
        username
        role {
          id
          name
        }
      }
    }
  }
`

export const logoutMutation = gql`
  mutation {
    unauthenticateUser {
      success
    }
  }
`