import React, { useEffect, useState } from 'react';

import { CAlert, CBadge, CButton, CCard, CCardBody, CJumbotron, CModal, CModalBody, CModalHeader } from '@coreui/react';
import _ from 'lodash';

import './style.scss'
import CIcon from '@coreui/icons-react';
import { cilPen, cilTrash } from '@coreui/icons';
import AdditionalQuestionsForm from '../AdditionalQuestionsForm';
import DeleteButton from '../DeleteButton';

const AdditionalQuestions = ({ loading, submitting, error, additionalQuestions, allowEdit, onUpdateQuestion, onDeleteQuestion }) => {
  const [showForm, setShowForm] = useState(false);
  const [idToEdit, setIdToEdit] = useState('');

  useEffect(() => {
    setShowForm(false);
  }, [additionalQuestions, setShowForm])

  if (loading) {
    return <CJumbotron color="light">Loading questions...</CJumbotron>
  }

  const typemap = {
    shorttext: 'Short Text',
    longtext: 'Long Text',
    yesno: 'Yes/No Question',
    radio: 'Multiple Choices, Single Select',
    checkboxes: 'Multiple Choices, Multiselect'
  }

  const startEditQuestion = (id) => () => {
    setIdToEdit(id);
    setShowForm(true);
  }

  const doneEditQuestion = () => {
    setIdToEdit('');
    setShowForm(false);
  }

  const renderQuestionItem = ({ id, label, shortlabel, type, required, options }) => (
    <CCard
      className="question-card shadow-sm"
    >
      <CCardBody className="question-body">
        <div className="main-content">
          <h6>
            <b>{label}</b>
            {(shortlabel && shortlabel !== label) ? (<i> ({shortlabel})</i>) : ''}
          </h6>
          <small>{typemap[type]}{required ? ', Required' : ''}</small>
          {!_.isEmpty(options) && (
            <div>
              <small>Options:&nbsp;</small>
              {options && options.map(({ name }) => <CBadge color="secondary">{name}</CBadge>)}
            </div>
          )}
        </div>
        {(!!id && allowEdit) && (
          <>
            <CButton shape="pill" size="sm" variant="ghost" color="success" onClick={startEditQuestion(id)}>
              <CIcon content={cilPen} />
            </CButton>
            <DeleteButton shape="pill" size="sm" variant="ghost" color="danger" onClick={onDeleteQuestion(id)}>
              <CIcon content={cilTrash} />
            </DeleteButton>
          </>
        )}
      </CCardBody>
    </CCard>
  )

  const questionToEdit = (idToEdit && additionalQuestions.find((q) => q.id === idToEdit)) || {};

  const formModal = allowEdit && (
    <CModal closeOnBackdrop={false} size="md" show={showForm} onClose={doneEditQuestion}>
      <CModalHeader closeButton>Edit question</CModalHeader>
      <CModalBody>
        {!!error && <CAlert closeButton color="danger">{error}</CAlert>}
        <AdditionalQuestionsForm question={questionToEdit} submitting={submitting} submitButtonText="Save" onSubmit={onUpdateQuestion} />
      </CModalBody>
    </CModal>
  )

  console.log(additionalQuestions)

  return (
    <div className="additional-questions">
      {renderQuestionItem({ label: 'Name', type: 'shorttext', required: true })}
      {renderQuestionItem({ label: 'Email', type: 'shorttext', required: true })}
      {renderQuestionItem({ label: 'Phone Number', type: 'shorttext', required: true })}
      {additionalQuestions && additionalQuestions.map(renderQuestionItem)}
      {allowEdit && <CButton onClick={() => setShowForm(true)} color="primary" variant="outline">Add new question</CButton>}
      {formModal}
    </div>
  )
}

export default AdditionalQuestions;