import { CContainer } from '@coreui/react';
import React from 'react';
import onlyAdmin from '../onlyAdmin';

const Home = () => (
  <CContainer fluid>
    <h1>Dashboard is coming soon...</h1>
    <p className="read">Coming soon</p>
  </CContainer>
)

export default onlyAdmin(Home);