import React from 'react';
import onlyAdmin from '../onlyAdmin';

const Page404 = () => (
  <section>
    <h1>404</h1>
    <p className="read">Halaman tidak ditemukan</p>
  </section>
)

export default onlyAdmin(Page404);