import React from 'react';
import { Form, withFormik } from 'formik';
import FormField from '../FormField';
import { CButton } from '@coreui/react';
import ImageUpload from '../ImageUpload';

import './style.scss'
import FormWrapper from '../FormWrapper';

const ImageUploadForm = (props) => {
  return (
    <FormWrapper className="image-upload-form">
      <FormField
        name="image"
        component={ImageUpload}
        disabled={props.submitting}
      />
    </FormWrapper>
  )
}

export default withFormik({
  mapPropsToValues: ({ image = null }) => ({ image }),
  handleSubmit: (values, { props }) => {
    props.onSubmit(values)
  }
})(ImageUploadForm)