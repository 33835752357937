import React, { forwardRef } from 'react';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import ReactDatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import { CButton } from '@coreui/react';

import './style.scss'
import { startOfDay } from 'date-fns';

const InputButton = forwardRef(
  ({ value, onClick }, ref) => (
    <CButton color="dark" type="button" variant={value ? '' : 'outline'} onClick={onClick} ref={ref}>
      {value ? <b>{value}</b> : <i>...click to select date and time</i>}
    </CButton>
  ),
);

const DateTimePicker = ({ value, name, setValue, setTouched, showTime, ...props }) => {
  const wibValue = value ? utcToZonedTime(value, 'Asia/Jakarta') : null;

  const onValueChange = (date) => {
    const utcDate = date ? zonedTimeToUtc(date, 'Asia/Jakarta') : null;
    setValue(showTime ? utcDate : startOfDay(utcDate));
    setTouched()
  }

  const onBlur = () => {
    setTouched()
  }

  return (
    <ReactDatePicker
      {...props}
      wrapperClassName="date-time-picker form-control"
      customInput={<InputButton />}
      selected={wibValue}
      onChange={onValueChange}
      onBlur={onBlur}
      showTimeSelect={showTime}
      showPopperArrow={false}
      timeFormat="HH:mm"
      dateFormat={showTime ? 'EEEE, MMMM d yyyy, HH:mm' : 'EEEE, MMMM d yyyy'}
    />
  )
}

export default DateTimePicker