import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CCard, CCardBody } from '@coreui/react';

import './style.scss'
import { format } from 'date-fns-tz';

const EventCard = ({ event }) => {
  const history = useHistory();
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);

  const { id, name, time } = event;

  const navigateToEventDetail = (eventId) => () => {
    setClicked(true);
    history.push(`/events/${eventId}`)
    setClicked(false);
  }

  return (
    <CCard
      className={`event-card ${clicked ? 'shadow' : 'shadow-sm'} ${hovered ? 'bg-light' : 'bg-white'}`}
      onClick={navigateToEventDetail(id)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <CCardBody>
        <h5><b>{name}</b></h5>
        <p>{format(new Date(time), "EEEE, MMMM do yyyy, HH:mm", { timeZone: 'Asia/Jakarta' })}</p>
      </CCardBody>
    </CCard>
  )
}

export default EventCard;