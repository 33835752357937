import React from 'react';
import { CButton, CInput, CFormGroup, CImg, CInvalidFeedback } from '@coreui/react';
import { withFormik, Form, Field } from 'formik';
import * as Yup from 'yup';

import logo from '../../images/logo-intors.png';

const LoginForm = ({ isSubmitting, errors, touched }) => (
  <Form className="login-form">
    <CImg
      className="intors-img"
      src={logo}
    />
    <CFormGroup>
      <Field
        as={CInput}
        type="email"
        name="loginEmail"
        placeholder="Email"
        autoComplete="email"
        invalid={(errors.loginEmail && touched.loginEmail)}
      />
      {(errors.loginEmail && touched.loginEmail) && (<CInvalidFeedback>{errors.loginEmail}</CInvalidFeedback>)}
    </CFormGroup>
    <CFormGroup>
      <Field
        as={CInput}
        type="password"
        name="loginPassword"
        placeholder="Password"
        autoComplete="current-password"
        invalid={(errors.loginPassword && touched.loginPassword)}
      />
      {(errors.loginPassword && touched.loginPassword) && (<CInvalidFeedback>{errors.loginPassword}</CInvalidFeedback>)}
    </CFormGroup>
    <CButton
      color="primary"
      type="submit"
      disabled={isSubmitting}
    >
      {isSubmitting ? 'Logging you in...' : 'Log in'}
    </CButton>
  </Form>
)

export default withFormik({
  mapPropsToValues: () => ({ loginEmail: '', loginPassword: '' }),
  handleSubmit: ({ loginEmail, loginPassword }, { props }) => (
    props.onSubmit(loginEmail, loginPassword)
  ),
  validationSchema: Yup.object().shape({
    loginEmail: Yup.string().email('Please enter a valid email address.').required('You should enter a valid email address.'),
    loginPassword: Yup.string().required('You should enter your password')
  })
})(LoginForm)