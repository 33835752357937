import React, { useState } from 'react';
import { CContainer, CCard, CAlert } from '@coreui/react';
import { useMutation } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';

import { loginMutation } from '../../graphql/login';
import { useAuth } from '../../hooks/auth';

import LoginForm from './Login.form';

import './style.scss';

const Login = ({ history }) => {
  const [error, setError] = useState('');

  const {
    isAuthed,
    setAuth
  } = useAuth();

  const [login, { loading }] = useMutation(
    loginMutation,
    {
      onCompleted: (data) => {
        const jwt = _.get(data, 'login.jwt');
        const { id, username, role } = _.get(data, 'login.user') || {};

        if (jwt && id && role && role.id === '3') {
          setAuth({
            adminToken: jwt,
            adminId: id,
            adminName: username
          })
          history.push('/');
        } else {
          setError('You are not tutors. Only tutors can log in to this admin portal');
          throw new Error('You are not tutors. Only tutors can log in to this admin portal');
        }
      },
      onError: (e) => {
        setError('Unable to log in, please try again');
        throw e;
      }
    }
  )

  if (isAuthed) {
    return <Redirect to="/home" exact />
  }

  const hideError = () => setError('');

  const onSubmit = (email, password) => {
    hideError();
    return login({ variables: { email, password } })
  }

  return (
    <CContainer className="login">
      <div className="flexer top">
        {!!error && <CAlert closeButton color="danger">{error}</CAlert>}
      </div>
      <CCard>
        <LoginForm
          onSubmit={onSubmit}
        />
      </CCard>
      <div className="flexer" />
    </CContainer>
  )
}

export default Login