import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { CookiesProvider } from 'react-cookie';

import Home from './containers/Home';
import Login from './containers/Login';
import Page404 from './containers/Page404';
import Events from './containers/Events';
import EventDetail from './containers/EventDetail';

import { useAuth } from './hooks/auth';
import AddEvent from './containers/AddEvent';
import { ToastProvider } from 'react-toast-notifications';

function App() {
  const {
    auth: { adminToken }
  } = useAuth();

  const client = new ApolloClient({
    link: createUploadLink({
      uri: `${process.env.REACT_APP_SERVER_URL}/graphql`,
      headers: {
        ...(adminToken ? { Authorization: `Bearer ${adminToken}` } : {})
      }
    }),
    cache: new InMemoryCache()
  })

  return (
    <BrowserRouter>
      <CookiesProvider>
        <ApolloProvider client={client}>
          <ToastProvider placement="top-right" autoDismiss autoDismissTimeout={3000}>
            <Switch>
              <Route path="/404" component={Page404} />
              <Route path="/events/add" component={AddEvent} />
              <Route path="/events/:id" component={EventDetail} />
              <Route path="/events" exact component={Events} />
              <Route path="/login" component={Login} />
              <Route path="/" exact component={Home} />
            </Switch>
          </ToastProvider>
        </ApolloProvider>
      </CookiesProvider>
    </BrowserRouter>
  );
}

export default App;
