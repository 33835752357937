import React from 'react';
import { Form, withFormik } from 'formik';
import FormField from '../FormField';
import { CButton, CTextarea } from '@coreui/react';
import DateTimePicker from '../DateTimePicker';
import { startOfToday, startOfTomorrow } from 'date-fns';
import * as Yup from 'yup';

import FormWrapper from '../FormWrapper';

const tomorrow = startOfTomorrow();
const today = startOfToday();

const EventForm = (props) => {
  const { submitting } = props;

  return (
    <FormWrapper className="event-form" {...props}>
      <FormField disabled={submitting} name="name" label="Event Name" required />
      <FormField disabled={submitting} name="description" label="Event Description" component={CTextarea} rows={5} />
      <FormField
        name="time"
        label="Event Date and Time"
        required
        component={DateTimePicker}
        showTime
        minDate={today}
        disabled={submitting}
      />
      <FormField disabled={submitting} name="price" label="Event Fee (in USD)" required type="number" />
      {/* <FormField
        name="openat"
        label="Registration Opens At"
        required
        component={DateTimePicker}
        minDate={tomorrow}
        disabled={submitting}
      /> */}
    </FormWrapper>
  )
}

export default withFormik({
  mapPropsToValues: ({ event }) => {
    const {
      name = '',
      description = '',
      time = null,
      openat = tomorrow,
      currency = 'USD',
      price = 0
    } = event || {};

    return { name, description, time, openat, currency, price }
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required('You must input your event name.'),
    description: Yup.string(),
    time: Yup.date()
      .required('You must input event date and time.')
      .typeError('You must input event date and time.')
      .min(today, 'You cannot set event date and time on past date.'),
    price: Yup.number()
      .required('We do not support creating free events for now, so price must not be zero')
      .min(1, 'We do not support creating free events for now, so price must not be zero')
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit(values)
  }
})(EventForm)