import { useCookies } from "react-cookie";

export const useAuth = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['intors-admin-token', 'intors-admin-id', 'intors-admin-name']);

  const cookieConfig = {
    path: '/'
  }

  const auth = {
    adminToken: cookies['intors-admin-token'],
    adminId: cookies['intors-admin-id'],
    adminName: cookies['intors-admin-name']
  }

  const isAuthed = cookies['intors-admin-token'] && cookies['intors-admin-id'] && cookies['intors-admin-name'];

  const setAuth = ({ adminToken, adminId, adminName }) => {
    if (adminToken) {
      setCookie('intors-admin-token', adminToken, cookieConfig)
    }

    if (adminId) {
      setCookie('intors-admin-id', adminId, cookieConfig)
    }

    if (adminName) {
      setCookie('intors-admin-name', adminName, cookieConfig)
    }
  }

  const removeAuth = (key) => {
    switch (key) {
      case 'token': {
        removeCookie('intors-admin-token', cookieConfig);
        break;
      }

      case 'id': {
        removeCookie('intors-admin-id', cookieConfig);
        break;
      }

      case 'name': {
        removeCookie('intors-admin-name', cookieConfig);
        break;
      }

      default: {
        removeCookie('intors-admin-token', cookieConfig);
        removeCookie('intors-admin-id', cookieConfig);
        removeCookie('intors-admin-name', cookieConfig);
        break;

      }
    }
  }

  return { auth, isAuthed, setAuth, removeAuth };
}