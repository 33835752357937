import { gql } from '@apollo/client';

export const additionalQuestionFragment = gql`
  fragment additionalQuestion on AdditionalQuestion {
    id
    label
    shortlabel
    key
    type
    required
    options
    placeholder
    hint
  }
`;

export const productBaseFragment = gql`
  fragment productBase on Product {
    id
    name
    image {
      url
    }
    description
    quota
    time
    openat
    closeat
    currency
    price
  }
`;

export const productFragment = gql`
  fragment product on Product {
    ...productBase
    additional_questions {
      ...additionalQuestion
    }
  }
  ${productBaseFragment}
  ${additionalQuestionFragment}
`;

export const getUpcomingEventsQuery = gql`
  query ($today: DateTime) {
    products(where: { time_gte: $today } ) {
      ...productBase
    }
  }
  ${productBaseFragment}
`

export const getPastEventsQuery = gql`
  query ($today: DateTime) {
    products(where: { time_lt: $today } ) {
      ...productBase
    }
  }
  ${productBaseFragment}
`

export const getEventByIdQuery = gql`
  query ($id: ID!) {
    product(id: $id) {
      ...product
    }
  }
  ${productFragment}
`

export const getEventQuestionsQuery = gql`
  query ($id: ID!) {
    product(id: $id) {
      id
      additional_questions {
        ...additionalQuestion
      }
    }
  }
  ${additionalQuestionFragment}
`

export const addEventMutation = gql`
  mutation (
    $name: String!,
    $description: String,
    $quota: Int,
    $time: DateTime!,
    $openat: Date!,
    $closeat: Date!,
    $currency: ENUM_PRODUCT_CURRENCY!,
    $price: Int!,
    $image: ID
  ) {
    createProduct(
      input: {
        data: {
          name: $name
          description: $description
          quota: $quota
          time: $time
          openat: $openat
          closeat: $closeat
          currency: $currency
          price: $price
          image: $image
        }
      }
    ) {
      product {
        ...product
      }
    }
  }
  ${productFragment}
`;

export const updateEventMutation = gql`
  mutation (
    $id: ID!,
    $name: String!,
    $description: String,
    $quota: Int,
    $time: DateTime!,
    $openat: Date!,
    $closeat: Date!,
    $currency: ENUM_PRODUCT_CURRENCY!,
    $price: Int!,
    $image: ID
  ) {
    updateProduct(
      input: {
        where: { id: $id },
        data: {
          name: $name
          description: $description
          quota: $quota
          time: $time
          openat: $openat
          closeat: $closeat
          currency: $currency
          price: $price
          image: $image
        }
      }
    ) {
      product {
        ...product
      }
    }
  }
  ${productFragment}
`;

export const addQuestionMutation = gql`
  mutation (
    $label: String!
    $shortlabel: String!
    $type: ENUM_ADDITIONALQUESTION_TYPE
    $product: ID!
    $required: Boolean
    $options: JSON
  ) {
    createAdditionalQuestion(
      input: {
        data: {
          label: $label
          shortlabel: $shortlabel
          type: $type
          product: $product
          required: $required
          options: $options
        }
      }
    ) {
      additionalQuestion {
        ...additionalQuestion
      }
    }
  }
  ${additionalQuestionFragment}
`;

export const updateQuestionMutation = gql`
  mutation (
    $id: ID!
    $label: String!
    $shortlabel: String!
    $type: ENUM_ADDITIONALQUESTION_TYPE
    $product: ID!
    $required: Boolean
    $options: JSON
  ) {
    updateAdditionalQuestion(
      input: {
        where: { id: $id },
        data: {
          label: $label
          shortlabel: $shortlabel
          type: $type
          product: $product
          required: $required
          options: $options
        }
      }
    ) {
      additionalQuestion {
        ...additionalQuestion
      }
    }
  }
  ${additionalQuestionFragment}
`;


export const deleteQuestionMutation = gql`
  mutation (
    $id: ID!
  ) {
    deleteAdditionalQuestion(
      input: {
        where: { id: $id }
      }
    ) {
      additionalQuestion {
        ...additionalQuestion
      }
    }
  }
  ${additionalQuestionFragment}
`;