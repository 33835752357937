import React from 'react';
import { FieldArray, Form, withFormik } from 'formik';
import FormField from '../FormField';
import { CButton, CSwitch } from '@coreui/react';
import Dropdown from '../Dropdown';
import _ from 'lodash';
import CIcon from '@coreui/icons-react';
import { cilTrash } from '@coreui/icons';
import * as Yup from 'yup';

import './style.scss'
import FormWrapper from '../FormWrapper';

const typeOptions = [
  { id: 'shorttext', name: 'Short Text' },
  { id: 'longtext', name: 'Long Text' },
  { id: 'yesno', name: 'Yes/No Question' },
  { id: 'radio', name: 'Multiple Choices, Single Select' },
  { id: 'checkboxes', name: 'Multiple Choices, Multiselect' },
]

const AdditionalQuestionsForm = (props) => {
  const { values, submitting } = props;
  const { type, required, options } = values;

  const showOptionsInput = ['radio', 'checkboxes'].includes(type);

  const renderOptionsField = (arrayHelper) => {
    return (
      <div className="options-field">
        {!_.isEmpty(options) && options.map(({ id }, index) => (
          <div key={id} className="options-item">
            <FormField dense className="options-input" name={`options[${index}].name`} />
            <CButton className="options-remove" shape="pill" onClick={() => arrayHelper.remove(index)} size="sm" variant="ghost" color="danger">
              <CIcon size="sm" content={cilTrash} />
            </CButton>
          </div>
        ))}
        <CButton onClick={() => arrayHelper.push({})} color="primary" variant="outline">Add option</CButton>
      </div>
    )
  }

  return (
    <FormWrapper className="additional-questions-form" {...props}>
      <FormField
        disabled={submitting}
        name="required"
        component={CSwitch}
        checked={required}
        size="lg"
        shape="pill"
        color="primary"
        label="Set as required question"
        orientation="vertical"
      />
      <FormField
        disabled={submitting}
        name="label"
        label="Question"
        required
        placeholder="e.g. What is your nationality?"
        hint="The question text displayed to participants"
      />
      <FormField
        disabled={submitting}
        name="shortlabel"
        label="Key"
        required
        placeholder="e.g. Nationality"
        hint="This is 1-2 word(s) key of the question, displayed in participants table"
      />
      <FormField
        disabled={submitting}
        name="type"
        label="Question Type"
        required
        component={Dropdown}
        options={typeOptions}
      />
      {showOptionsInput && (
        <FormField
          disabled={submitting}
          name="options"
          render={renderOptionsField}
          component={FieldArray}
          label="Options"
        />
      )}
    </FormWrapper>
  )
}

export default withFormik({
  mapPropsToValues: ({ question }) => {
    const {
      id = `new-${(new Date()).getTime()}`,
      label = '',
      shortlabel = '',
      type = 'shorttext',
      required = false,
      options = []
    } = question || {};

    return { id, label, shortlabel, type, required, options }
  },
  enableReinitialize: true,
  validationSchema: Yup.object().shape({
    label: Yup.string().required('You must enter the question text'),
    shortlabel: Yup.string()
      .required('You must enter the question key')
      .matches(/[A-Za-z0-9]+(\S[A-Za-z0-9]+)/g, 'The key is too long, please put 1-2 words only as question key'),
    type: Yup.string().required('You must enter the question type'),
    options: Yup.array()
      .when('type', {
        is: (type) => type === 'radio' || type === 'checkboxes',
        then: Yup.array()
          .compact()
          .required('You must add options for your question.')
          .min(1, 'You must add options for your question.'),
        otherwise: Yup.array().nullable()
      })
  }),
  handleSubmit: (values, { props }) => {
    const { shortlabel, options } = values;
    let updatedOptions = [];
    if (!_.isEmpty(options)) {
      let nextIdNumber = 1;
      updatedOptions = options.map(({ id, name }) => {
        if (id) {
          const parsedId = id.match(/\d+$/);
          const parsedIdNumber = (parsedId && parsedId[0] && Number(parsedId[0])) || 0;
          nextIdNumber = parsedIdNumber + 1;
          return { id, name }
        } else {
          const newId = `${shortlabel.replace(/[^A-Za-z0-9]/g, '').toLowerCase()}-${nextIdNumber}`;
          nextIdNumber = nextIdNumber + 1;
          return { id: newId, name }
        }
      })
    }
    props.onSubmit({ ...values, options: updatedOptions })
  }
})(AdditionalQuestionsForm)