import { gql } from '@apollo/client';

export const uploadMutation = gql`
  mutation($file: Upload!) {
    upload(file: $file) {
      id
      name
    }
  }
`;

export const changeEventImageMutation = gql`
  mutation($eventId: ID!, $file: Upload!) {
    upload(
      file: $file,
      ref: "product",
      refId: $eventId,
      field: "image"
    ) {
      id
      name
    }
  }
`;