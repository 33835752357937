import { CFormGroup, CFormText, CInput, CLabel } from '@coreui/react';
import { useField } from 'formik';
import React from 'react';

import './style.scss'

const FormField = ({ name, orientation, component, hint, label, required, children, dense, ...otherprops }) => {
  const [field, meta, helper] = useField(name);

  const hasError = (meta.error && meta.touched);

  const InputComponent = component || CInput;

  const inputView = (
    <div className="input-wrapper">
      <InputComponent {...field} {...helper} {...otherprops} invalid={hasError} />
      {(!!hint && !hasError) && (<CFormText className="help-block">{hint}</CFormText>)}
      {hasError && (<CFormText color="danger">{meta.error}</CFormText>)}
    </div>
  )

  return (
    <CFormGroup className={`form-field ${orientation || 'horizontal'} ${dense ? 'dense' : ''}`}>
      {label && (
        <CLabel className="field-label" htmlFor={name}>
          <b>{label}</b>
          {required && <CFormText color="danger"><b>*</b></CFormText>}
        </CLabel>
      )}
      {inputView}
    </CFormGroup>
  )
}

export default FormField;